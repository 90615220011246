<template>
    <div id="app"> 
        <div class="zNavigationBar">
            การตรวจสอบด้วยใบหน้า
         </div>
         <div class="mainContainer">
            <div class="tipHeader">
               โปรดเตรียมตัวสแกนใบหน้า
            </div>
            <div class="imgContainer">
               <div class="gifContainer"><img src="https://sg-production-cdn.zoloz.com/page/face-guide-fe/img/faceguide.e4594d56.gif" class="faceGuideImage"></div>
            </div>
            <div class="tipHeader">
               แจ้งเตือนการสแกนใบหน้า
            </div>
            <div class="tipText">&nbsp;&nbsp;· โปรดทำให้แน่ใจว่าคุณอยู่ในที่ที่มีแสงสว่างเพียงพอ<br>&nbsp;&nbsp;· กรุณาแสดงใบหน้าให้อยู่ในช่องสแกน<br>&nbsp;&nbsp;· โปรดอยู่นิ่งในระหว่างการสแกนอัตโนมัติ<br>&nbsp;&nbsp;· อย่าสวมหน้ากากในขณะที่สแกนใบหน้า</div>
         </div>
         <div class="footer">
            <div class="zBottomButton" @click="takeSelfie">
               การตรวจสอบด้วยใบหน้า
            </div>
         </div>
    </div>
</template>

<script>
  import ZolozRealIdCore,{ekycLog} from './assets/zolozRealIdCore.js';

  export default {
      name: 'app',
      data() {
          return {
              enableNext: true,
              zolozRealIdCore: new ZolozRealIdCore({}),
          };
      },
      mounted() {
          ekycLog('faceGuidePageAppear', {});
          document.addEventListener('back', e => {
            e.preventDefault();
            ekycLog('clickButton', {
              'onPage': 'face_guide',
              'clickItem': 'back'
            });
            this.zolozRealIdCore.end('back');
          }, false);
      },
      methods: {
          async takeSelfie() {
            window.parent.postMessage('next','*')
            if(this.enableNext) {
              ekycLog('faceGuidePageProceed');
              this.enableNext = false;
              await this.zolozRealIdCore.end('next');
              this.enableNext = true;
              
            } 
          }
      }
  }
</script>

<style> 
</style>
